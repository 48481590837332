import * as React from 'react'

import { SEO } from '../../components/seo'
import { FeatureLabel } from '../../components/feature-label'

type Props = {}

export default class StyleguidePage extends React.Component<Props, {}> {
  public render() {
    return (
      <>
        <SEO title="Styleguide" />
        <header className="container container--grid">
          <div className="container__row">
            <h1>Feature Label</h1>
          </div>
        </header>
        <section className="container container--grid">
          <div className="container__row">
            <FeatureLabel text="New Article" />
          </div>
        </section>
      </>
    )
  }
}
